import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import Loading from '../LoadingPlaceholder';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../Collapsible';
import { Calculator } from '../Calculator';
import { CalculatorFinanceable } from '../CalculatorFinanceable';

import { setGenericEvent } from '../../store/actions/dataLayer';

import './styles.css';
import {useIntl} from 'react-intl';
import { getMessages } from '../../tppServices/translations/messages';
import { getBoatLoanConstants } from '../../constants/BoatLoans';
import { isFinanceable } from '../../utils/trident';

const PaymentCardTitle = () => {
  const intl = useIntl();
  const formatMessage = intl.formatMessage;
  const messages = getMessages();
  const { paymentCalculator } = messages.boatLoansLeads.getPreQualified;
  const boatLoansConstants = getBoatLoanConstants();
  const { CALCULATOR_IMG, YACHTWORLD_LOGO } = boatLoansConstants;
  return (
    <div className="calc-card-title-container">
      <div>
        <img width="42" height="42" alt="loan-calculator" src={CALCULATOR_IMG} />
      </div>
      <div className="calc-card-title">
        <img width="116" height="22" alt="BoatTrader" src={YACHTWORLD_LOGO} />
        <span className="calc-card-text">{formatMessage(paymentCalculator.srpTitle)}</span>
      </div>
    </div>
  );
};

const PaymentCalculator = (props) => {

  const {
    isLoading,
    listing,
    tridentLoanAmount,
    tridentTeaserRate,
    tridentTermInMonths,
    counter,
    showMonthlyCalculatorOnly,
    useAsCard,
    initialState = 'open',
    ctaLink,
    supportsTridentCalculator,
    isBDP = false,
  } = useMemo(() => props, [props]);

  const [price, setPrice] = useState(tridentLoanAmount);
  const [openComponent, setOpenComponent] = useState(initialState);
  const intl = useIntl();
  const formatMessage = intl.formatMessage;
  const messages = getMessages();
  const { paymentCalculator } = messages.boatLoansLeads.getPreQualified;
  const hideTridentFeatures = listing?.owner?.hideTridentFeatures ?? false;
  const financeable = isFinanceable(tridentLoanAmount, listing?.year, true);
  const shouldShowFinanceableCalculator = isBDP && !hideTridentFeatures && financeable;

  useEffect(() => {
    const hidden = get(listing, 'price.hidden');
    setPrice(!hidden && tridentLoanAmount);
    setOpenComponent(openComponent);
  }, [listing, tridentLoanAmount]);

  const renderPaymentCalculator = () => {
    return supportsTridentCalculator ? (
      <CollapsibleContent
        open={openComponent}
        openCounter={counter}
        initialState={initialState}
        {...(!useAsCard && { classOverride: 'details' })}>
        <CollapsibleHeader>
          {useAsCard ? <PaymentCardTitle /> : formatMessage(paymentCalculator.title)}
        </CollapsibleHeader>

        <Collapsible type="no-pad">
          <div className="auto-calculator">
            {shouldShowFinanceableCalculator ? (
              <CalculatorFinanceable
                setGenericEvent={setGenericEvent}
                tridentLoanAmount={price}
                tridentTeaserRate={tridentTeaserRate}
                tridentTermInMonths={tridentTermInMonths}
                listing={listing}
              />
            ) : (
              <Calculator
                setGenericEvent={setGenericEvent}
                tridentLoanAmount={price}
                tridentTeaserRate={tridentTeaserRate}
                tridentTermInMonths={tridentTermInMonths}
                ctaLink={ctaLink}
                showMonthlyCalculatorOnly={showMonthlyCalculatorOnly}
                isPrequalCTA={true}
                hideTridentFeatures={hideTridentFeatures}
              />
            )}
          </div>
        </Collapsible>
      </CollapsibleContent>
    ) : null;
  };

  const renderLoading = () => {
    return (
      <>
        <Loading type="text" />
        <Loading type="text" />
        <Loading type="text" />
      </>
    );
  };

  return (
    <div className="payment-calculator-wrapper" id="scroll-to-calculator">
      <div id="payment-calculator-anchor"></div>
      <div className={classNames('payment-calculator', { 'payment-calculator-card': useAsCard })} >
        {isLoading ?
          renderLoading()
          :
          renderPaymentCalculator()}
      </div>
    </div>
  );
};

PaymentCalculator.propTypes = {
  isLoading: PropTypes.bool,
  listing: PropTypes.any,
  tridentLoanAmount: PropTypes.any,
  tridentTeaserRate: PropTypes.any,
  tridentTermInMonths: PropTypes.any,
  counter: PropTypes.number,
  hideTridentFeatures: PropTypes.bool,
  showMonthlyCalculatorOnly: PropTypes.bool,
  useAsCard: PropTypes.bool,
  initialState: PropTypes.string,
  ctaLink: PropTypes.string,
  supportsTridentCalculator: PropTypes.bool,
};

export default PaymentCalculator;
